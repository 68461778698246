import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FollowUpKeys } from '@gen2/api/follow-up/hooks';
import { TInviteRequest } from '@gen2/api/invite-requests/api';
import {
  InviteRequestsKeys,
  useAddContactInRequestMutation,
  useDeleteContactInRequestMutation,
} from '@gen2/api/invite-requests/hooks';
import { useActionModalStore } from '@gen2/app/components/action-modal/store';
import { FeatureFlags } from '@gen2/app/components/feature-flags/feature-flags';
import { useSendInviteStore } from '@gen2/app/invites/send-invites/store';
import { queryClient } from '@gen2/config';
import { useRouter, useToast } from '@gen2/hooks';
import { TContact } from '@gen2/types/contact';
import { contactsToTags } from '@gen2/utils/contacts';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  RequestContactDescription,
  RequestContactsContainer,
  StyledCheckbox,
  StyledSelect,
  StyledSelectOption,
  StyledSendTo,
} from './request-contact.styled';

export type TRequestContactProps = {
  request: TInviteRequest;
  contacts: TContact[];
};

export const RequestContacts = React.memo(
  ({ request, contacts }: TRequestContactProps) => {
    const { t } = useTranslation('sendInvite');
    const toast = useToast();
    const route = useRouter();
    const { showModal } = useActionModalStore();
    const { inviteId } = useParams<{ inviteId: string; }>();

    const isInEditInvitePage =
      route.pathname.startsWith('/send-invites/') &&
      route.pathname.split('/').length > 2;

    const siStore = useSendInviteStore();

    const invite = useMemo(() => {
      return siStore.invite;
    }, [siStore.invite]);

    const isFollowUpRequest = useMemo(() => {
      return request.is_follow_up_request ?? false;
    }, [request]);

    const { mutateAsync: deleteContactInRequest } =
      useDeleteContactInRequestMutation();
    const { mutateAsync: addContactInRequest } =
      useAddContactInRequestMutation();

    const contactIds = contacts.flatMap((contact) => contact.id);

    const [selectedContacts, setSelectedContacts] =
      useState<string[]>(contactIds);

    const handleDeleteRequestContact = async (contactId: string) => {
      if (selectedContacts.length <= 1) {
        await showModal({
          header: t('editSendInvite.requestContact.delete.remaining.title'),
          message: 'editSendInvite.requestContact.delete.remaining.message',
          translationNamespace: 'sendInvite',
          closeButtonLabel:
            t('editSendInvite.requestContact.delete.remaining.close') ?? '',
        });

        return;
      }

      if (invite.status !== 'draft' && !isFollowUpRequest) {
        const { isConfirmed } = await showModal({
          header: t('editSendInvite.requestContact.delete.confirm.title'),
          message: 'editSendInvite.requestContact.delete.confirm.message',
          translationNamespace: 'sendInvite',
          closeButtonLabel:
            t('editSendInvite.requestContact.delete.confirm.close') ?? '',
          submitButtonLabel:
            t('editSendInvite.requestContact.delete.confirm.submit') ?? '',
        });

        if (!isConfirmed) {
          return;
        }
      }

      try {
        await deleteContactInRequest({
          inviteId: inviteId || siStore.invite.id,
          requestId: request.id,
          contactId,
        });

        setSelectedContacts((prev) => prev.filter((id) => id !== contactId));

        await queryClient.invalidateQueries({ queryKey: [InviteRequestsKeys.getInviteRequests] });
        await queryClient.invalidateQueries({ queryKey: [FollowUpKeys.getFollowUps] });

        if (isFollowUpRequest && !isInEditInvitePage) {
          return;
        }

        const toastMessage = invite.status === 'draft'
          ? t('draftSaved')
          : t('notDraftSaved');

        toast.show({
          text: toastMessage,
          variant: 'success',
        });
      } catch (err: unknown) {
        const response = err as AxiosResponse;

        if (response.status === 422) {
          showModal({
            header: t('editSendInvite.requestContact.notAssigned.title'),
            rawMessage: response.data.message,
            closeButtonLabel:
              t('editSendInvite.requestContact.notAssigned.close') ?? '',
          });

          return;
        }
      }
    };

    const handleAddRequestContact = async (contactId: string) => {
      try {
        await addContactInRequest({
          inviteId: inviteId || siStore.invite.id,
          requestId: request.id,
          contactId,
        });

        setSelectedContacts((prev) => [...prev, contactId]);

        await queryClient.invalidateQueries({ queryKey: [InviteRequestsKeys.getInviteRequests] });
        await queryClient.invalidateQueries({ queryKey: [FollowUpKeys.getFollowUp] });
        await queryClient.invalidateQueries({ queryKey: [FollowUpKeys.getFollowUps] });

        if (isFollowUpRequest && !isInEditInvitePage) {
          return;
        }

        const toastMessage = invite.status === 'draft'
          ? t('draftSaved')
          : t('notDraftSaved');

        toast.show({
          text: toastMessage,
          variant: 'success',
        });
      } catch (err: unknown) {
        const response = err as AxiosResponse;

        toast.show({
          text: response?.data?.message,
          variant: 'error',
        });
      }
    };

    const handleCheckboxClick = (contactId: string) => {
      if (!selectedContacts.includes(contactId)) {
        handleAddRequestContact(contactId);

        return;
      }

      handleDeleteRequestContact(contactId);
    };

    return (
      <FeatureFlags restriction="assign_contact_to_request">
        <RequestContactsContainer>
          <RequestContactDescription>
            {t('editSendInvite.requestContact.label')}
          </RequestContactDescription>
          <StyledSelect
            labelId="demo-multiple-chip-label"
            id="demo-multiple-chip"
            multiple
            value={selectedContacts}
            renderValue={(selectedRaw: unknown) => {
              const selected = selectedRaw as string[];

              const selectedContacts = contacts.filter((contact) => {
                return selected.includes(contact.id);
              });

              return (
                <StyledSendTo
                  data-cy="si-send-to-label"
                  onDeleteTag={(contactId) =>
                    handleDeleteRequestContact(contactId)
                  }
                  tags={contactsToTags(selectedContacts)}
                  isLoading={false}
                  disabled={false}
                  hiddenButton={true}
                />
              );
            }}
          >
            {invite.contacts.map((contact, index) => (
              <StyledSelectOption
                key={index}
                onClick={() => handleCheckboxClick(contact.id)}
              >
                <StyledCheckbox
                  checked={selectedContacts.includes(contact.id)}
                  icon={<FontAwesomeIcon icon={regular('square')} />}
                  checkedIcon={
                    <FontAwesomeIcon icon={regular('square-check')} />
                  }
                />
                <span>{`${contact.first_name} ${contact.last_name}`}</span>
              </StyledSelectOption>
            ))}
          </StyledSelect>
        </RequestContactsContainer>
      </FeatureFlags>
    );
  },
  (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
  },
);
