import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '@gen2/hooks';
import { useSidebarStore } from '@gen2/hooks/stores';
import { useMemo } from 'react';
import { contacts, MenuItem } from '../data';
import {
  StyledSidebarList,
  StyledSidebarListItem,
  StyledSidebarListItemLink,
  StyledSubMenuHeading,
} from '../sidebar.styled';

export const Contacts = () => {
  const store = useSidebarStore();
  const { featureFlags } = useAuth();

  const onItemClick = (item: MenuItem) => {
    store.setOpen(false);

    if (item.id === 'home') return; // dashboard has no submenu

    store.setSelected(item);
    store.setSubMenuOpen(false);
  };

  // Dynamically filter the contacts/group based on the corresponding feature flag for each item
  const displayContacts = useMemo(() => {
    return contacts.filter(
      (item) =>
        item.featureFlag &&
        featureFlags?.[item.featureFlag as keyof typeof featureFlags],
    );
  }, [featureFlags]);

  return (
    <StyledSidebarList data-cy="sidebar-users">
      <StyledSubMenuHeading>CONTACTS</StyledSubMenuHeading>
      {displayContacts.map((item) => (
        <StyledSidebarListItem key={item.title}>
          <StyledSidebarListItemLink
            onClick={() => onItemClick(item)}
            to={item.to || ''}
            data-cy={`sidebar-users-link-${item?.title?.toLowerCase()}`}
          >
            <FontAwesomeIcon size="lg" icon={item.icon || 'x'} />
            <span>{item.title}</span>
          </StyledSidebarListItemLink>
        </StyledSidebarListItem>
      ))}
    </StyledSidebarList>
  );
};
